import React from "react";
import { Controller } from "react-hook-form";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";

import Select from "react-select";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import GoogleMap from "../../../../google-map-react-2.2/src";
import { useState } from "react";
import  { usePlacesWidget } from "react-google-autocomplete";

export default function PropertyAddress({
    stepper,
  control,
  register,
  errors,
  regions,
  cities,
  setValue
  
}) {

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey:'AIzaSyCgnWQH5St0k9FXSg02etIAmgBGUGd0iq8',
    language:"so",
    onPlaceSelected: (place) => {
      setValue("location.address",place.formatted_address)
      setValue("location.lat",place.geometry.location.lat())
      setValue("location.long",place.geometry.location.lng())
    },
    options: {
      types: [],
      componentRestrictions: { country: "so" },
    },
  });

  // const defaultProps = {
  //   center: {
  //     lat: 2.0469,
  //     lng: 45.3182,
  //   },
  //   zoom: 15,
  // };
  // const [coordinates, setCoordinates] = useState({
  //   latitude: defaultProps.center.lat,
  //   longitude: defaultProps.center.lng,
  // });

 
  const nextClick = ()=>{
    stepper.next()
  }

  return (
    <>
      <input type="search" ref={ref} className='form-control' />
      <Row className="gy-1 gx-2">
        
      {/* <Col xs={12} md={3}>
            <Label className="form-label" for="location.address">
              Address
            </Label>

            <Controller
              name="location.address"
              control={control}
              render={({ field }) => (
              
                <Input
                  id="location.address"
                  {...field}
                />
              )}
            />
            {errors.location?.country && (
              <FormFeedback>Please enter a valid Address</FormFeedback>
            )}
          </Col> */}
      {/* <Col xs={12} md={3}>
            <Label className="form-label" for="location.country">
              Country
            </Label>

            <Controller
              name="location.country"
              control={control}
              render={({ field }) => (
              
                <Select
                  id="location.country"
                  className="react-select"
                classNamePrefix="select"
                  options={[{label:'Somalia',value:'Somalia'}]}
                  placeholder=""
                  invalid={errors.location?.country && true}
                  {...field}
                />
              )}
            />
            {errors.location?.country && (
              <FormFeedback>Please enter a valid Email</FormFeedback>
            )}
          </Col>

          <Col xs={12} md={3}>
            <Label className="form-label" for="location.state">
              State
            </Label>

            <Controller
              name="location.state"
              control={control}
              rules={{
                required:true
              }}
              render={({ field }) => (
                <Select
                  id="location.state"
                  placeholder=""
                  className="react-select"
                classNamePrefix="select"
                  options={regions.map((reg)=>({label:reg,value:reg}))} 
                  invalid={errors.location?.state && true}
                  {...field}
                />
              )}
            />
            {errors.location?.state && (
              <FormFeedback>Please enter a valid Email</FormFeedback>
            )}
          </Col>
          <Col xs={12} md={3}>
            <Label className="form-label" for="location.city">
              City
            </Label>

            <Controller
              name="location.city"
              control={control}
              rules={{required:true}}
              render={({ field }) => (
                <Select
                  id="location.city"
                  className="react-select"
                classNamePrefix="select"
                  options={cities.map((ct)=>({label:ct,value:ct}))}
                  placeholder=""
                  invalid={errors.location?.city && true}
                  {...field}
                />
              )}
            />
            {errors.location?.city && (
              <FormFeedback>Please enter a valid Email</FormFeedback>
            )}
          </Col>
          <Col xs={12} md={3}>
            <Label className="form-label" for="location.area">
              Area
            </Label>

            <Controller
              name="location.area"
              control={control}
              render={({ field }) => (
                <Input
                  id="location.area"
                  type="text"
                  {...register("location.area", { required: true })}
                  placeholder=""
                  invalid={errors.location?.city && true}
                  {...field}
                />
              )}
            />
            {errors.location?.city && (
              <FormFeedback>Please enter a valid area</FormFeedback>
            )}
          </Col> */}

          {/* <Col xs={12}>
            <Label className="form-label">Select Property Location</Label>
            <div style={{ height: "350px" }}>
              <GoogleMap
                apiKey={""}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onClick={(e) => console.log(e)}
              />
            </div>
          </Col> */}
      </Row>

      <div className="d-flex justify-content-between mt-4">
        <Button color="secondary" className="btn-prev" onClick={()=>stepper.previous()}>
          <ArrowLeft
            size={14}
            className="align-middle me-sm-25 me-0"
          />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight
            size={14}
            className="align-middle ms-sm-25 ms-0"
          />
        </Button>
      </div>
    </>
  );
}
