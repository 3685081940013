// ** React Imports
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

// ** Store & Actions

import { useSelector, useDispatch } from "react-redux";

// ** Reactstrap Imports
import { Row, Col, Alert, Spinner } from "reactstrap";
import axios from "axios";

// ** User View Components
import UserTabs from "./Tabs";
import PlanCard from "./PlanCard";
import UserInfoCard from "./UserInfoCard";

// ** Styles
import "@styles/react/apps/app-users.scss";
import { ErrorHandler } from "../../../common/utils/Error";
import PropertyCardInfo from "./UserInfoCard";
import PropertyTabs from "./Tabs";

const UserView = () => {
  // ** Store Vars
  const store = useSelector((state) => state.users);
  const dispatch = useDispatch();

  // ** Hooks
  const { buildingId } = useParams();

  // ** Get suer on mount
  useEffect(() => {
    // dispatch(getUser(parseInt(id)))
  }, [dispatch]);

  const [active, setActive] = useState("1");

  const toggleTab = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const [property, setProperty] = useState();
  const [loading, setloading] = useState(false);

  const getPropertyDetail = async () => {
    setloading(true);
    try {
      const { data, status } = await axios.get(`/property/${buildingId}`);

      if (status == 200) {
        setProperty(data.data);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getPropertyDetail();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return property ? (
    <div className="app-user-view">
      <Row>
        <Col xl="3" lg="4" xs={{ order: 1 }} md={{ order: 0, size: 5 }}>
          <PropertyCardInfo property={property} />
        </Col>
        <Col xl="9" lg="8" xs={{ order: 0 }} md={{ order: 1, size: 7 }}>
          <PropertyTabs
            active={active}
            toggleTab={toggleTab}
            property={property}
          />
        </Col>
      </Row>
    </div>
  ) : null;
};
export default UserView;
