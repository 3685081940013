import React, { useContext } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
// ** Icons Imports
import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import {
  ChevronDown,
  Copy,
  File,
  FileText,
  Grid,
  Printer,
  Share,
  Trash2,
  User,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import ReactPaginate from "react-paginate";

import moment from "moment/moment";
import { PencilFill, PersonAdd } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableLoading from "../../@core/components/base/TableLoading";
import HandleDispatch from "../../common/utils/HandledDispatch";
import {
  deleteCustomer,
  getAllCustomers,
  getData,
  searchFilter,
  selectCustomer,
} from "../../redux/customer";
import Loading from "../loading";
import CustomerModel from "./components/customerModel";
import { useNavigate, useSearchParams } from "react-router-dom";
import Can2 from "../../utility/Can";
import { CurrentBuildingContext } from "../../contexts/currentBuilding";

const MySwal = withReactContent(Swal);

const CustomHeader = ({
  store,
  toggleSidebar,
  handlePerPage,
  rowsPerPage,
  searchTerm,
  handleFilter,
}) => {
  // ** Converts table to CSV
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(store.data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  // ** Downloads CSV
  function downloadCSV(array) {
    array = array.map((item, idx) => {
      var nData = { ...item };
      delete nData.company;
      delete nData.role;
      return nData;
    });

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "customers.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row>
        <Col xl="6" className="d-flex align-items-center p-0">
          <div className="d-flex align-items-center w-100">
            <label htmlFor="rows-per-page">Show</label>
            <Input
              className="mx-50"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handlePerPage}
              style={{ width: "5rem" }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="50">100</option>
            </Input>
            <label htmlFor="rows-per-page">Entries</label>
          </div>
        </Col>
        <Col
          xl="6"
          className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
        >
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              Search:
            </label>
            <Input
              id="search-invoice"
              className="ms-50 w-100"
              type="text"
              value={searchTerm}
              onChange={(e) => {
                handleFilter(e.target?.value);
              }}
            />
          </div>

          <div className="d-flex align-items-center table-header-actions">
            <UncontrolledDropdown className="me-1">
              <DropdownToggle color="secondary" caret outline>
                <Share className="font-small-4 me-50" />
                <span className="align-middle">Export</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="w-100">
                  <Printer className="font-small-4 me-50" />
                  <span className="align-middle">Print</span>
                </DropdownItem>
                <DropdownItem
                  className="w-100"
                  onClick={() => {
                    // var exct = store.data.map((v)=>({...v,}))
                    downloadCSV(store.data);
                  }}
                >
                  <FileText className="font-small-4 me-50" />
                  <span className="align-middle">CSV</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Grid className="font-small-4 me-50" />
                  <span className="align-middle">Excel</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <File className="font-small-4 me-50" />
                  <span className="align-middle">PDF</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Copy className="font-small-4 me-50" />
                  <span className="align-middle">Copy</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default function Customers() {
  const [showmodal, setModalShow] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const dispatch = useDispatch();

  const { currentBuilding } = useContext(CurrentBuildingContext);

  const navigate = useNavigate();

  const store = useSelector((state) => state.customers);

  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const handleConfirmDelete = async (id, name) => {
    return MySwal.fire({
      title: `Delete Customer ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },

      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const deletedId = await HandleDispatch(dispatch, deleteCustomer(id));
        if (!deletedId) {
          return false;
        }
      }
    });
  };
  // themeConfig.serverUrl + row.photo
  const columns = [
    {
      name: "Customer",
      sortable: true,
      sortField: "name",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            navigate(`/customers/${row._id}`);
            // dispatch(selectCustomer(row));
          }}
        >
          <div className="avatar rounded">
            <div className="avatar-content">
              <User />
            </div>
          </div>
          <div className="ms-1">
            <div className="fw-bolder">{row.name}</div>
            <div className="font-small-2 text-muted">{row.username}</div>
          </div>
        </div>
      ),
    },

    {
      name: "Phone",
      sortable: true,
      sortField: "phone",
      selector: (row) => row.phone ?? "",
      cell: (row) => <div className="">{row.phone ?? ""}</div>,
    },
    {
      name: "Email",
      sortable: true,
      sortField: "email",
      selector: (row) => row.email ?? "",
      cell: (row) => <div className="">{row.email ?? ""}</div>,
    },
    {
      name: "Status",
      minWidth: "100px",
      sortable: true,
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <Badge
          color={row.status == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">
            {row.status == "active" ? "Active" : "InActive"}
          </span>
        </Badge>
      ),
    },
    {
      name: "Reg. Date",
      minWidth: "100px",
      sortable: true,
      sortField: "date",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {" "}
          {moment(row.createdAt).format("DD-MMM-YYYY")}
        </span>
      ),
    },
    {
      name: "User",
      minWidth: "100px",
      sortable: true,
      sortField: "user",
      selector: (row) => row.createdBy,
      cell: (row) => <span className="">{row.createdBy?.username}</span>,
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="column-action">
          <Can2 I="update" a="Customer">
            <Button.Ripple
              className="btn-icon"
              size="sm"
              color="flat-primary"
              onClick={(e) => {
                dispatch(selectCustomer(row));
                setModalShow(true);
              }}
            >
              <PencilFill size={15} />
            </Button.Ripple>
          </Can2>
          <Can2 I="delete" a="Customer">
            <Button.Ripple
              className="btn-icon"
              size="sm"
              color="flat-danger"
              onClick={(e) => {
                handleConfirmDelete(row._id, row.name);
              }}
            >
              <Trash2 size={15} />
            </Button.Ripple>
          </Can2>

          {/* <UncontrolledDropdown>
            <DropdownToggle tag="div" className="btn btn-sm">
              <MoreVertical size={14} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();

                  dispatch(selectCategory(row));
                  setModalShow(true);
                }}
              >
                <Archive size={14} className="me-50" />
                <span className="align-middle">Edit</span>
              </DropdownItem>
              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  handleConfirmDelete(row._id, row.name);
                }}
              >
                <Trash2 size={14} className="me-50" />
                <span className="align-middle">Delete</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [currentRole, setCurrentRole] = useState({
    value: "",
    label: "Select Role",
  });

  const [currentStatus, setCurrentStatus] = useState({
    value: "",
    label: "Select Status",
    number: 0,
  });

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handleFilter = (val) => {
    setsearchTerm(val);
    dispatch(searchFilter(val));
  };

  const dataToRender = () => {
    return store.data;
  };

  const ToggleModal = (show) => {
    if (typeof show == "boolean") {
      setModalShow(show);
    } else {
      setModalShow(!showmodal);
    }
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
      })
    );
    setRowsPerPage(value);
  };

  useEffect(() => {
    console.log("yes");
    dispatch(getAllCustomers({}));
  }, []);

  return (
    <Fragment>
      <Card>
        <CardBody className="d-flex align-items-start justify-content-between">
          <CardTitle tag="h4">Customer Information</CardTitle>
          <CustomerModel show={showmodal} ShowModal={ToggleModal} />

          <Can2 I="create" a="Customer">
            <Button
              className=""
              color="primary"
              onClick={() => setModalShow(true)}
            >
              <PersonAdd className="me-1" size={20} /> Add Tenant
            </Button>
          </Can2>
        </CardBody>
        {/* <CardBody>
          <Row>
            <Col sm={12} md={4}>
            <Label for="role-select">Role</Label>
              <Select
                isClearable={false}
                options={[]}
                className="react-select"
                classNamePrefix="select"
                onChange={(data) => {
                  
                }}
              />
            </Col>
            <Col sm={12} md={4}>
              <Select />
            </Col>
            <Col sm={12} md={4}>
              <Select />
            </Col>
          </Row>
        </CardBody> */}
      </Card>
      <Card className="overflow-hidden">
        <div className="react-dataTable">
          {store.loading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              striped
              columns={columns}
              // onSort={handleSort}
              sortIcon={<ChevronDown />}
              className="react-dataTable card-company-table"
              paginationComponent={CustomPagination}
              data={dataToRender()}
              progressPending={store.loading && !store.data.length}
              progressComponent={<TableLoading rows={3} cols={7} />}
              subHeaderComponent={
                <CustomHeader
                  store={store}
                  searchTerm={searchTerm}
                  rowsPerPage={rowsPerPage}
                  handleFilter={handleFilter}
                  handlePerPage={handlePerPage}
                  // toggleSidebar={toggleSidebar}
                />
              }
            />
          )}
        </div>
      </Card>
    </Fragment>
  );
}
