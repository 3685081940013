import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { ArrowRight } from "react-bootstrap-icons";
import { ArrowLeft } from "react-feather";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select/creatable";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Spinner,
} from "reactstrap";

export default function OwnerForm({
  stepper,
  control,
  register,
  errors,
  setValue,
  owners,
})
 {

 const [showForm, setshowForm] = useState(false);

  const nextClick = () => {
    stepper.next();
  };

  return (
    <>
    <div className="mb-2">
          <Label className="form-label" for="owner_id">
            Property Owner
          </Label>

          <Controller
            name="owner_id"
            control={control}
            rules={{required:!showForm}}
            render={({ field }) => (
              <ReactSelect
                id="owner_id"
                invalid={errors.owner_id && true}
               
                {...field}
                // value={buildingTypes.find(b=>b.value==field.value)}
                options={owners?.map((ct) => ({
                  value: ct._id,
                  label: ct.name+" - "+ct.phone,
                }))}
                
                 onChange={(v)=>{
                  setshowForm(false);
                  field.onChange(v)
                }}
                onCreateOption={(value)=>{
                    if (value) {
                        setValue("owner.name",value)
                        setValue("owner_id","")
                        setshowForm(true)
                    }
                }}
              />
            )}
          />
          {errors.owner_id && (
            <FormFeedback>Please Select Owner</FormFeedback>
          )}
        </div>
      <Row className={classNames("gy-1 gx-2 flex-column",{"d-none":!showForm})}>
        <Col xs={12}>
          <Label className="form-label" for="owner.name">
            Owner Name
          </Label>
          <Controller
            name="owner.name"
            control={control}
            rules={{required:showForm}}
            render={({ field }) => (
              <Input
                id="owner.name"
                placeholder="Owner name"
                
                invalid={errors?.owner?.name && true}
                {...field}
              />
            )}
          />
          {errors?.owner?.name && (
            <FormFeedback>Please enter a Name</FormFeedback>
          )}
        </Col>
        <Col xs={12}>
          <Label className="form-label" for="owner.phone">
            Phone
          </Label>
          <Controller
            name="owner.phone"
            control={control}
            rules={{ required: showForm }}
            render={({ field }) => (
              <Input
                id="owner.phone"
                placeholder="+2526"
                invalid={errors?.owner?.phone && true}
                {...field}
              />
            )}
          />
          {errors?.owner?.phone && (
            <FormFeedback>Please enter a Phone</FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="owner.phone">
            Email
          </Label>
          <Controller
            name="owner.email"
            control={control}
            rules={{ required: showForm }}
            render={({ field }) => (
              <Input
                id="owner.phone"
                placeholder="admin@example.com"
                invalid={errors?.owner?.email && true}
                {...field}
              />
            )}
          />
          {errors?.owner?.email && (
            <FormFeedback>Please enter a Email</FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="owner.phone">
            Emergency Contact
          </Label>
          <Controller
            name="owner.emergency_contact"
            control={control}
            rules={{ required: showForm }}
            render={({ field }) => (
              <Input
                id="owner.phone"
                placeholder="+2526"
                invalid={errors?.owner?.emergency_contact && true}
                {...field}
              />
            )}
          />
          {errors?.owner?.emergency_contact && (
            <FormFeedback>Please enter a Emergency Contact</FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="owner.address">
            Address
          </Label>
          <Controller
            name="owner.address"
            control={control}
            rules={{ required: showForm }}
            render={({ field }) => (
              <Input
                id="owner.address"
                placeholder="Taleex,Hodan Mogadishu Somalia"
                invalid={errors.owner?.address && true}
                {...field}
              />
            )}
          />
          {errors.owner?.address && (
            <FormFeedback>Please enter a valid Address</FormFeedback>
          )}
        </Col>
      </Row>
      <div className="d-flex justify-content-between mt-2">
      <Button color="secondary" className="btn-prev" outline disabled>
          <ArrowLeft
            size={14}
            className="align-middle me-sm-25 me-0"
          />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
       
        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
        </Button>
      </div>
    </>
  );
}
