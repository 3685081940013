import React from "react";
import { Controller } from "react-hook-form";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";

import Select from "react-select";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { slugify } from "../../../../utility/Utils";

export default function PropertyInformation({
  stepper,
  control,
  register,
  errors,
  categories,
  getValues,
  setValue,
  trigger,
  appView,
}) {
  var propertyStatus = [
    { value: "For Sale", label: "For Sale" },
    { value: "Broker Rent", label: "Broker Rent" },
    { value: "For Rent", label: "For Rent" },
  ];

  const nextClick = () => {
    stepper.next();
  };

  return (
    <>
      <Row className="gy-1 gx-2">
        <Col xs={12} md={6}>
          <Label className="form-label" for="name">
            Property Name
          </Label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                id="name"
                {...register("name", { required: true })}
                placeholder="Burj Tower"
                invalid={errors.name && true}
                {...field}
                onChange={(value) => {
                  setValue("slug", slugify(value.target.value));
                  trigger("slug");
                  field.onChange(value);
                }}
              />
            )}
          />
          <span className="text-success">slug: {getValues().slug}</span>
          {errors.name && (
            <FormFeedback>Please enter a valid Name</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={6}>
          <Label className="form-label" for="category">
            Property Type
          </Label>

          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Select
                id="category"
                className="react-select"
                classNamePrefix="select"
                invalid={errors.category && true}
                {...register("category", { required: true })}
                {...field}
                // value={buildingTypes.find(b=>b.value==field.value)}
                options={categories.map((ct) => ({
                  value: ct._id,
                  label: ct.name,
                }))}
                // onChange={(v)=>field.onChange(v.value)}
              />
            )}
          />
          {errors.category && (
            <FormFeedback>Please enter a valid Category</FormFeedback>
          )}
        </Col>

        {appView ? (
          <Col xs={12} md={3}>
            <Label className="form-label" for="propertyStatus">
              Property Purpose
            </Label>

            <Controller
              name="propertyStatus"
              control={control}
              render={({ field }) => (
                <Select
                  id="propertyStatus"
                  className="react-select"
                  classNamePrefix="select"
                  invalid={errors.propertyStatus && true}
                  {...register("propertyStatus", { required: true })}
                  {...field}
                  options={propertyStatus}
                  onChange={(v) => {
                    field.onChange(v);
                    trigger("propertyStatus");
                  }}
                />
              )}
            />
            {errors.propertyStatus && (
              <FormFeedback>Please enter a valid Property Status</FormFeedback>
            )}
          </Col>
        ) : null}

        <Col xs={12} md={3}>
          <Label className="form-label" for="phone">
            Contact Phone
          </Label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                id="phone"
                type="phone"
                {...register("phone", { required: true })}
                placeholder="25261...."
                invalid={errors.phone && true}
                {...field}
              />
            )}
          />
          {errors.phone && (
            <FormFeedback>Please enter a valid Email</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="type">
            Contact Email
          </Label>

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                id="email"
                min={0}
                type="email"
                {...register("email", { required: true })}
                step={"any"}
                placeholder="admin@rukun.so"
                invalid={errors.email && true}
                {...field}
              />
            )}
          />
          {errors.email && (
            <FormFeedback>Please enter a valid Email</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={3}>
          <Label className="form-label" for="floors">
            Floors
          </Label>
          <Controller
            name="floors"
            control={control}
            render={({ field }) => (
              <Input
                id="floors"
                type="number"
                placeholder="4"
                {...register("floors", { required: true })}
                invalid={errors.floors && true}
                {...field}
              />
            )}
          />
          {errors.floors && (
            <FormFeedback>Please enter a valid floor</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={3}>
          <Label className="form-label" for="landArea">
            Land Area
          </Label>

          <Controller
            name="landArea"
            control={control}
            render={({ field }) => (
              <Input
                id="landArea"
                type="text"
                {...register("landArea", { required: true })}
                placeholder=""
                invalid={errors.landArea && true}
                {...field}
              />
            )}
          />
          {errors.landArea && (
            <FormFeedback>Please enter a valid landArea</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="garages">
            Garages
          </Label>

          <Controller
            name="garages"
            control={control}
            render={({ field }) => (
              <Input
                id="garages"
                type="text"
                {...register("garages", { required: true })}
                placeholder=""
                invalid={errors.garages && true}
                {...field}
              />
            )}
          />
          {errors.garages && (
            <FormFeedback>Please enter a valid Garages</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="garageSize">
            Garage Size
          </Label>

          <Controller
            name="garageSize"
            control={control}
            render={({ field }) => (
              <Input
                id="garageSize"
                type="text"
                {...register("garageSize", { required: true })}
                placeholder=""
                invalid={errors.garageSize && true}
                {...field}
              />
            )}
          />
          {errors.garageSize && (
            <FormFeedback>Please enter a valid garageSize</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={3}>
          <Label className="form-label" for="yearBuilt">
            Year Built
          </Label>

          <Controller
            name="yearBuilt"
            control={control}
            render={({ field }) => (
              <Input
                id="yearBuilt"
                type="number"
                {...register("yearBuilt", { required: true })}
                placeholder=""
                invalid={errors.yearBuilt && true}
                {...field}
              />
            )}
          />
          {errors.yearBuilt && (
            <FormFeedback>Please enter a valid garageSize</FormFeedback>
          )}
        </Col>

        <Col xs={12}>
          <Label className="form-label" for="description">
            Description
          </Label>

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                id="description"
                type="textarea"
                invalid={errors.description && true}
                {...field}
              />
            )}
          />
          {errors.description && (
            <FormFeedback>Please enter a valid Description</FormFeedback>
          )}
        </Col>
      </Row>

      <div className="d-flex justify-content-between mt-2">
        <Button
          color="secondary"
          className="btn-prev"
          onClick={() => stepper.previous()}
        >
          <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
        </Button>
      </div>
    </>
  );
}
