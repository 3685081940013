import { ErrorHandler } from "./Error";
import { AxiosError } from "axios";

const { unwrapResult } = require("@reduxjs/toolkit");

export default async function HandleDispatch(dispatch, action) {
  try {
    const result = await dispatch(action);
    return await unwrapResult(result);
  } catch (error) {
    ErrorHandler(error);
  }
}
