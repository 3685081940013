// ** React Imports
import { Fragment } from "react";
// ** Reactstrap Imports
import { Card, CardHeader, CardTitle } from "reactstrap";

// ** Table Import
import ShiftsTable from "./components/DataTable";

const Permissions = () => {
  return (
    <Fragment>
      <Card>
        <CardHeader className="d-flex align-items-start">
          <CardTitle tag="h4">Shifts Information</CardTitle>
          {/* <DepartmentModel show={showmodal} ShowModal={ToggleModal} /> */}
          {/* <Button
            className=""
            color="primary"
            onClick={() => setModalShow(true)}
          >
            <BuildingAdd className="me-1" size={20} /> Add Department
          </Button> */}
        </CardHeader>
        <div className="card-datatable app-user-list table-responsive">
          <ShiftsTable />
        </div>
      </Card>
    </Fragment>
  );
};

export default Permissions;
