// ** React Imports
import { Fragment, useState } from "react";

import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

import { Controller, useForm } from "react-hook-form";

import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ErrorHandler } from "../../common/utils/Error";
import HandleDispatch from "../../common/utils/HandledDispatch";
import { createUtilityType, updateUtilityType } from "../../redux/utilityTypes";

import Select from "react-select";
import axios from "axios";

const NewUtilityType = ({ row, show, ShowModal }) => {
  const defaultValues = {
    type: "",
    unit: "",
    rate: "",
    isFixed: true,
    dr: "",
    cr: "",
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();
  const [btnloading, setBtnloading] = useState(false);

  const onSubmit = async (data) => {
    setBtnloading(true);
    try {
      if (row) {
        const response = await HandleDispatch(
          dispatch,
          updateUtilityType({ id: row._id, data })
        );
        if (response) {
          toast.success("Type Information updated Successfully");
          reset();
          ShowModal();
        }
      } else {
        const response = await HandleDispatch(
          dispatch,
          createUtilityType(data)
        );
        if (response) {
          toast.success("New Type Registered Succesfully");
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      console.error(error);
      ErrorHandler(error);
    } finally {
      setBtnloading(false);
    }
  };

  useEffect(() => {
    if (row) {
      reset({
        unit: row.unit,
        rate: row.rate,
        type: row.type,
        isFixed: !row.unit && !row.rate,
      });
    }
  }, [row]);

  const onDiscard = () => {
    ShowModal();
    reset(defaultValues);
  };

  return (
    <Fragment>
      <Modal isOpen={show} onClosed={onDiscard} toggle={onDiscard} className="">
        <ModalHeader toggle={onDiscard} className="py-1 bg-white">
          {row ? "Edit Type" : "Add New Type"}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 mx-50">
            <Col className="mb-1">
              <Label className="form-label" for="type">
                Type
              </Label>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="type"
                    placeholder="Electricity"
                    invalid={errors.type && true}
                    {...field}
                  />
                )}
              />
              {errors.type && (
                <FormFeedback>Please enter a valid Type</FormFeedback>
              )}
            </Col>

            <Col className="mb-1" md={2}>
              <Label className="form-label" for="isFixed">
                Fixed
              </Label>
              <div className="form-switch form-check-success">
                <Controller
                  name="isFixed"
                  control={control}
                  render={({ field }) => (
                    <Input
                      checked={getValues().isFixed}
                      onClick={(e) => {
                        setValue("isFixed", e.target.checked);
                        trigger("isFixed");

                        if (!e.target.checked) {
                          setValue("unit", "");
                          setValue("rate", "");
                        }
                      }}
                      id="isFixed"
                      type="switch"
                      placeholder="Electricity"
                      {...field}
                    />
                  )}
                />
              </div>

              {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
            </Col>

            {!getValues().isFixed && (
              <>
                <Col className="mb-1">
                  <Label className="form-label" for="unit">
                    Unit
                  </Label>
                  <Controller
                    name="unit"
                    control={control}
                    rules={{ required: !getValues().isFixed }}
                    render={({ field }) => (
                      <Input
                        id="unit"
                        placeholder="Ex: KW"
                        invalid={errors.unit && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.unit && (
                    <FormFeedback>Please enter a valid Unit</FormFeedback>
                  )}
                </Col>
                <Col className="mb-1">
                  <Label className="form-label" for="rate">
                    Rate
                  </Label>
                  <Controller
                    name="rate"
                    control={control}
                    rules={{ required: !getValues().isFixed }}
                    render={({ field }) => (
                      <Input
                        id="rate"
                        placeholder="Ex. 1"
                        invalid={errors.rate && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.rate && (
                    <FormFeedback>Please enter a valid Rate</FormFeedback>
                  )}
                </Col>
              </>
            )}

            <Col className="mb-1">
              <Label className="form-label" for="accountId">
                Debit Account
              </Label>
              <Controller
                name="accountId"
                control={control}
                render={({ field }) => (
                  <Select
                    id="accountId"
                    className="react-select"
                    classNamePrefix="select"
                    invalid={errors.accountId && true}
                    {...register("accountId", { required: true })}
                    {...field}
                    options={accounts?.map((ac) => ({
                      value: ac._id,
                      label: `${ac.name}`,
                      isDisabled: ac.systemAccount,
                    }))}
                  />
                )}
              />
              {errors.accountId && (
                <FormFeedback>Receipt Account Required</FormFeedback>
              )}
            </Col>

            <Col>
              <Label className="form-label" for="accountId">
                Credit Account
              </Label>
              <Controller
                name="accountId"
                control={control}
                render={({ field }) => (
                  <Select
                    id="accountId"
                    className="react-select"
                    classNamePrefix="select"
                    invalid={errors.accountId && true}
                    {...register("accountId", { required: true })}
                    {...field}
                    options={accounts?.map((ac) => ({
                      value: ac._id,
                      label: `${ac.name}`,
                      isDisabled: ac.systemAccount,
                    }))}
                  />
                )}
              />
              {errors.accountId && (
                <FormFeedback>Receipt Account Required</FormFeedback>
              )}
            </Col>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={btnloading}
            >
              <Spinner size={"sm"} hidden={!btnloading} className="me-1" />
              {btnloading ? "Loading.." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default NewUtilityType;
