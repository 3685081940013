import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import _ from 'lodash';

const SearchBar = ({ onSearchSubmit, clearResults = () => { } }) => {
    const [term, setTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(term);

    // update 'term' value after 1 second from the last update of 'debouncedTerm'
    useEffect(() => {
        const timer = setTimeout(() => setTerm(debouncedTerm), 1000);
        return () => clearTimeout(timer);
    }, [debouncedTerm])

    // submit a new search
    useEffect(() => {
        if (term !== '') onSearchSubmit(term);
        else clearResults();
    }, [term]);

    return (
        <Input
            id="search-invoice"
            className="ms-50 w-100"
            type="text"
            placeholder="Search ..."
            autoComplete='off'
            onChange={_.memoize(e => setDebouncedTerm(e.target.value))}
            value={debouncedTerm}
        />
    );
};

export default SearchBar;