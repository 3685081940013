import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
// ** Icons Imports
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Archive,
  ChevronDown,
  Copy,
  Edit2,
  File,
  FileText,
  Grid,
  MoreVertical,
  PlusCircle,
  Printer,
  Share,
  X,
  Trash2,
} from "react-feather";
import { toast } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import HandleDispatch from "../../common/utils/HandledDispatch";
import {
  cancelBooking,
  deleteBooking,
  getAllbookings,
  getData,
} from "../../redux/booking";
import { getAllCustomers } from "../../redux/customer";
import Loading from "../loading";
import AddNewAddress from "./components/form";
import { Pass, Receipt } from "react-bootstrap-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ReceiptModel from "./components/receiptModel";
import CancelModel from "./components/cancelModel";
import { ErrorHandler } from "../../common/utils/Error";
import Can2 from "../../utility/Can";

const MySwal = withReactContent(Swal);

export default function Booking() {
  const [showmodal, setModalShow] = useState(false);
  const [receiptModel, setReceiptModel] = useState(false);
  const [cancelModel, setCancelModel] = useState(false);
  const dispatch = useDispatch();
  const [selectedApplication, setSelectedApplication] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");

  const [cancelModelLoading, setCancelModelLoading] = useState(false);

  const store = useSelector((state) => state.bookings);

  const customers = useSelector((state) => state.customers.allData);
  const CurrentBuilding = useSelector((state) => state.CurrentBuilding);
  const [sectionals, setSectionals] = useState([]);

  const onCancelBooking = async (data) => {
    try {
      var response = await axios.patch(
        `/bookings/cancel/${data.bookingId}`,
        data
      );

      if (response.status == 200) {
        toast.success("Booking Canceled Successfully");
        setCancelModel(false);
      }
    } catch (error) {
      ErrorHandler(error);
      setCancelModelLoading(false);
    }
  };

  const handleCancelBooking = (id, name) => {
    MySwal.fire({
      title: `Cancel Application?`,
      text: "NOTE: This also will cause to cancel the invoice!",
      icon: "warning",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Reason",
      },
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-danger me-1",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        try {
          if (!reason) throw new Error("Please Enter the reason");

          var response = await axios.patch(`/bookings/cancel/${id}`, {
            reason,
          });

          if (response.status == 200) {
            toast.success("Booking Canceled Successfully");
            dispatch(getAllbookings({}));
            // setCancelModel(false); 666
          }
        } catch (error) {
          const errorMessage =
            error?.response?.data?.message ??
            error?.response?.data ??
            error.message ??
            "Something went wrong, please try again";

          Swal.showValidationMessage(errorMessage);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleConfirmDelete = async (id, name) => {
    return MySwal.fire({
      title: `Delete Application`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await dispatch(deleteBooking(id));

        if (responseStatus == 204) {
          toast.success("Building Has been Deleted.");
        }
      }
    });
  };

  const columns = [
    {
      name: "Customer",
      sortable: true,
      // minWidth: "300px",
      // sortField: "name",
      selector: (row) => row.customer,
      cell: (row) => (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <Link
              to={`/customers/${row?.customer?._id}`}
              target="_blank"
              className="user_name text-body" // Remove text-truncate class
              style={{ wordWrap: "break-word", maxWidth: "100%" }} // Add inline styles for text wrapping
            >
              <span className="fw-bolder">{row.customer?.name}</span>
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Unit",
      sortable: true,
      // minWidth: "172px",
      // sortField: "floors",
      selector: (row) => row.unit,
      cell: (row) => <span>{row.unit?.name}</span>,
    },
    {
      name: "Amount",
      // minWidth: "138px",
      // sortable: true,
      // sortField: "discount",
      selector: (row) => row.amount,
      cell: (row) => (
        <Badge color="primary" className="text-capitalize fw-bolder">
          ${row.amount}
        </Badge>
      ),
    },

    {
      name: "Paid",
      // minWidth: "138px",
      // sortable: true,
      // sortField: "discount",
      selector: (row) => null,
      cell: (row) => (
        <Badge color="light-warning" className="text-capitalize fw-bolder">
          ${row.paid ?? 0}
        </Badge>
      ),
    },
    {
      name: "Balance",
      // minWidth: "138px",
      // sortable: true,
      // sortField: "discount",
      selector: (row) => null,
      cell: (row) => (
        <Badge color="light-danger" className="text-capitalize fw-bolder">
          ${row.amount - row.paid}
        </Badge>
      ),
    },

    {
      name: "Status",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <Badge
            color={
              row.status == "approved"
                ? "success"
                : row.status === "pending"
                ? "warning"
                : "danger"
            }
            id={`status_${row._id}`}
          >
            {row.status}
          </Badge>
          {/* <span className='sr-only'>{row.status?'Active':'InActive'}</span> */}
          <UncontrolledTooltip placement="top" target={`status_${row._id}`}>
            {row.status}
          </UncontrolledTooltip>
        </>
      ),
    },
    {
      name: "User",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.createdBy,
      cell: (row) => row.createdBy?.username,
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="column-action">
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-sm">
              <MoreVertical size={14} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedApplication(row);
                  setModalShow(true);
                  // dispatch(selectBooking(row));
                  // setModalShow(true);
                }}
              >
                <Edit2 size={14} className="me-50" />
                <span className="align-middle">Edit</span>
              </DropdownItem>

              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  handleConfirmDelete(row._id, row.name);
                }}
              >
                <Trash2 size={14} className="me-50" />
                <span className="align-middle">Delete</span>
              </DropdownItem>

              {row.status == "pending" ? (
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancelBooking(row?._id);
                  }}
                >
                  <X size={14} className="me-50" />
                  <span className="align-middle">Cancel Booking</span>
                </DropdownItem>
              ) : null}

              {row.status == "pending" && row.amount - row.paid > 0 ? (
                <Can2 I="approve" a="Booking">
                  <DropdownItem
                    tag="a"
                    href="/"
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      setReceiptModel(true);
                      setSelectedApplication(row);
                    }}
                  >
                    <Receipt size={14} className="me-50" />
                    <span className="align-middle">Make Payment</span>
                  </DropdownItem>
                </Can2>
              ) : (
                row.amount - row.paid == 0 &&
                row.status !== "approved" && (
                  <Can2 I="cancel" a="Booking">
                    <DropdownItem
                      tag="a"
                      href="/"
                      className="w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/leases/create?application=${row._id}`);
                      }}
                    >
                      <Pass size={14} className="me-50" />
                      <span className="align-middle">Approve</span>
                    </DropdownItem>
                  </Can2>
                )
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next next-item"}
        previousClassName={"page-item prev prev-item"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const dataToRender = () => {
    return store.data;
  };
  const CustomHeader = ({
    store,
    toggleSidebar,
    handlePerPage,
    rowsPerPage,
    handleFilter,
    searchTerm,
  }) => {
    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(store.data[0]);

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    }
    // ** Downloads CSV
    function downloadCSV(array) {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(array);
      if (csv === null) return;

      const filename = "export.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    return (
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
        <Row>
          <Col xl="6" className="d-flex align-items-center p-0">
            <div className="d-flex align-items-center w-100">
              <label htmlFor="rows-per-page">Show</label>
              <Input
                className="mx-50"
                type="select"
                id="rows-per-page"
                value={rowsPerPage}
                onChange={handlePerPage}
                style={{ width: "5rem" }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Input>
              <label htmlFor="rows-per-page">Entries</label>
            </div>
          </Col>
          <Col
            xl="6"
            className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
          >
            <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
              <label className="mb-0" htmlFor="search-invoice">
                Search:
              </label>
              <Input
                id="search-invoice"
                className="ms-50 w-100"
                type="text"
                value={searchTerm}
                // onChange={(e) => handleFilter(e.target.value)}
              />
            </div>

            <div className="d-flex align-items-center table-header-actions">
              <UncontrolledDropdown className="me-1">
                <DropdownToggle color="secondary" caret outline>
                  <Share className="font-small-4 me-50" />
                  <span className="align-middle">Export</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="w-100">
                    <Printer className="font-small-4 me-50" />
                    <span className="align-middle">Print</span>
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => downloadCSV(store.data)}
                  >
                    <FileText className="font-small-4 me-50" />
                    <span className="align-middle">CSV</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Grid className="font-small-4 me-50" />
                    <span className="align-middle">Excel</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <File className="font-small-4 me-50" />
                    <span className="align-middle">PDF</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Copy className="font-small-4 me-50" />
                    <span className="align-middle">Copy</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  const ToggleModal = (show) => {
    if (typeof show == "boolean") {
      setModalShow(show);
    } else {
      setModalShow(!showmodal);
    }
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
        sort: { createdAt: "desc" },
      })
    );
    setRowsPerPage(value);
  };

  const getAvailableSectionals = async () => {
    try {
      var { status, data } = await axios.get("/sectionals/available/", {
        params: {
          building: CurrentBuilding?.data?._id,
        },
      });
      if (status == 200) {
        setSectionals(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getAllbookings({ status }));
    if (!customers.length) {
      dispatch(getAllCustomers());
    }
    if (!sectionals.length) {
      getAvailableSectionals();
    }
  }, [status]);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Rental Applications</CardTitle>

          <Can2 I="create" a="Booking">
            <Button
              className="add-new-user align-middle"
              color="primary"
              onClick={() => setModalShow(true)}
            >
              <PlusCircle size={18} /> New Application
            </Button>
          </Can2>
          <AddNewAddress
            show={showmodal}
            ShowModal={ToggleModal}
            data={{
              sectionals,
              getAvailableSectionals,
            }}
            selectedRow={selectedApplication}
            setSelectedRow={setSelectedApplication}
          />
        </CardHeader>
        <CardBody>
          {/* <Row>
          <Col md="6">
            <Label for="role-select">Select Type</Label>
            <Select
              isClearable={false}
              value={currentRole}
              options={roleOptions}
              className="react-select"
              classNamePrefix="select"
              theme={selectThemeColors}
              onChange={(data) => {
              
            }}
            />
          </Col>
          <Col md="6">
            <Label for="status-select">Status</Label>
            <Select
              theme={selectThemeColors}
              isClearable={false}
              className="react-select"
              classNamePrefix="select"
              options={statusOptions}
              value={currentStatus}
              onChange={(data) => {
               
              }}
            />
          </Col>
        </Row> */}
        </CardBody>
      </Card>

      <Card className="overflow-hidden">
        <div className="react-dataTable">
          {store.loading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              noHeader
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              columns={columns}
              // onSort={handleSort}
              sortIcon={<ChevronDown />}
              className="react-dataTable"
              paginationComponent={CustomPagination}
              data={dataToRender()}
              subHeaderComponent={
                <CustomHeader
                  store={store}
                  // searchTerm={searchTerm}
                  rowsPerPage={rowsPerPage}
                  // handleFilter={handleFilter}
                  handlePerPage={handlePerPage}
                  // toggleSidebar={toggleSidebar}
                />
              }
            />
          )}
        </div>
      </Card>
      <ReceiptModel
        show={receiptModel}
        onClose={() => setReceiptModel(false)}
        application={selectedApplication}
      />
      <CancelModel
        show={cancelModel}
        onClose={() => setCancelModel(false)}
        application={selectedApplication}
        submitHandler={onCancelBooking}
        cancelModelLoading={cancelModelLoading}
        setCancelModelLoading={setCancelModelLoading}
      />
    </Fragment>
  );
}
