import React, { forwardRef, useContext } from "react";

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

// ** Icons Imports
import {
  AlertTriangle,
  ChevronDown,
  Copy,
  File,
  FileText,
  Grid,
  MoreVertical,
  Printer,
  RefreshCcw,
  Share,
  Trash2,
} from "react-feather";

import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import ReactPaginate from "react-paginate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StatsHorizontal from "@components/widgets/stats/StatsHorizontal";
import defaultImg from "@src/assets/images/logo/logo.png";
import axios from "axios";
import classNames from "classnames";
import moment from "moment/moment";
import {
  Eye,
  EyeFill,
  FileEarmarkPdf,
  Link45deg,
  Pencil,
  PlusCircleDotted,
  ReceiptCutoff,
} from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import TableLoading from "../../@core/components/base/TableLoading";
import { ErrorHandler } from "../../common/utils/Error";
import HandleDispatch from "../../common/utils/HandledDispatch";
import themeConfig from "../../configs/themeConfig";
import {
  deleteAgreement,
  getAllagreements,
  getData,
  searchFilter,
} from "../../redux/agreements";
import { convertDaysToMonthsAndYears } from "../../utility/Utils";
import Loading from "../loading";
import GenerateInvoiceModel from "./components/generateInvoice";
import RenewAgreement from "./components/renew";
import TerminateAgreement from "./components/terminateAgreement";
import { CurrentBuildingContext } from "../../contexts/currentBuilding";
import Can2 from "../../utility/Can";

const MySwal = withReactContent(Swal);

const CustomHeader = ({
  store,
  toggleSidebar,
  handlePerPage,
  rowsPerPage,
  searchTerm,
  handleFilter,
}) => {
  const navigate = useNavigate();
  // ** Converts table to CSV
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(store.data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  // ** Downloads CSV
  function downloadCSV(array) {
    array = array.map((item, idx) => ({}));

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "customers.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row>
        <Col xl="6" className="d-flex align-items-center p-0">
          <div className="d-flex align-items-center w-100">
            <label htmlFor="rows-per-page">Show</label>
            <Input
              className="mx-50"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handlePerPage}
              style={{ width: "5rem" }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="50">100</option>
            </Input>
            <label htmlFor="rows-per-page">Entries</label>
          </div>
        </Col>
        <Col
          xl="6"
          className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
        >
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              Search:
            </label>
            <Input
              id="search-invoice"
              className="ms-50 w-100"
              type="text"
              value={searchTerm}
              onChange={(e) => {
                handleFilter(e.target?.value);
              }}
            />
          </div>

          <div className="d-flex align-items-center table-header-actions">
            <UncontrolledDropdown className="me-1">
              <DropdownToggle color="secondary" caret outline>
                <Share className="font-small-4 me-50" />
                <span className="align-middle">Export</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="w-100">
                  <Printer className="font-small-4 me-50" />
                  <span className="align-middle">Print</span>
                </DropdownItem>
                <DropdownItem
                  className="w-100"
                  onClick={() => {
                    // var exct = store.data.map((v)=>({...v,}))
                    downloadCSV(store.data);
                  }}
                >
                  <FileText className="font-small-4 me-50" />
                  <span className="align-middle">CSV</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Grid className="font-small-4 me-50" />
                  <span className="align-middle">Excel</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <File className="font-small-4 me-50" />
                  <span className="align-middle">PDF</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Copy className="font-small-4 me-50" />
                  <span className="align-middle">Copy</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default function Agreements() {
  const [showmodal, setModalShow] = useState(false);
  const [generateModal, setGenerateModal] = useState(false);
  const [terminateModal, setTerminateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const { currentBuilding } = useContext(CurrentBuildingContext);

  const [renewModal, setRenewModal] = useState(false);

  const [searchTerm, setsearchTerm] = useState("");
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");

  const [agreementStats, setAgreementStats] = useState(null);
  const [agreementStatsLoading, setAgreementStatsLoading] = useState(false);

  const store = useSelector((state) => state.agreements);

  const navigate = useNavigate();

  const handleConfirmDelete = async (id) => {
    return MySwal.fire({
      title: `Delete Agreement?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        var deletedID = await HandleDispatch(dispatch, deleteAgreement(id));
        if (!deletedID) {
          toast.success("Agreement Deleted Successfully");
        }
      }
    });
  };

  const reclaimUnits = (agreement) => {
    MySwal.fire({
      title: `Are sure to release expired units?`,
      text: `NOTE:  Please be aware that you may not be able to renew all expired leases. 
      Note that renewing expired units is subject to limitations.`,
      icon: "warning",

      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      customClass: {
        confirmButton: "btn btn-danger me-1",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        try {
          var response;
          if (agreement) {
            response = await axios.post(
              `/agreements/reclaim?agreement=${agreement._id}`,
              {}
            );
          } else {
            response = await axios.post("/agreements/reclaim", {});
          }

          if (response.status == 200) {
            toast.success("Done Successfully");
          }
        } catch (error) {
          const errorMessage =
            error?.response?.data?.message ??
            error?.response?.data ??
            error.message ??
            "Something went wrong, please try again";

          Swal.showValidationMessage(errorMessage);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleTerminateAgreement = (row) => {
    MySwal.fire({
      title: `Terminate Agreement?`,
      // text: "NOTE: This also will cause to cancel the invoice!",
      icon: "warning",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Reason",
      },
      showCancelButton: true,
      confirmButtonText: "Yes, Terminate it!",
      customClass: {
        confirmButton: "btn btn-danger me-1",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        try {
          if (!reason) throw new Error("Please Enter the reason");

          var response = await axios.post("/agreements/terminate", {
            reason,
            agreement: row?._id,
          });

          if (response.status == 200) {
            toast.success("Agreement Terminated Successfully");
            dispatch(getAllagreements({ building: currentBuilding?._id }));
            getAgreementsStats();
          }
        } catch (error) {
          const errorMessage =
            error?.response?.data?.message ??
            error?.response?.data ??
            error.message ??
            "Something went wrong, please try again";

          Swal.showValidationMessage(errorMessage);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  // themeConfig.serverUrl + row.photo
  const columns = [
    {
      name: "Customer",
      sortable: true,
      sortField: "name",
      minWidth: "300px",
      // width: "20%",
      selector: (row) => row.user?.name ?? "",
      cell: (row) => (
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate(`/customers/${row.user._id}`)}
        >
          <div className="avatar rounded ">
            <div className="avatar-content">
              <img
                src={defaultImg}
                className="w-100 h-100 object-fit-scale"
                alt={row.user?.name}
              />
            </div>
          </div>
          <div className="ms-1">
            <div className="fw-bolder">{row.user?.name}</div>
            <div className="font-small-2 text-muted">{row.user?.email}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Property",
      sortable: true,
      selector: (row) => row.unit?.name ?? "",
    },
    {
      name: "Amount",
      sortable: true,
      selector: (row) => row.amount ?? "",
      cell: (row) => (
        <Badge color="light-primary">
          ${row.amount ?? ""} / {row.recursion} Month(s)
        </Badge>
      ),
    },

    {
      name: "Start Date",
      sortable: true,
      sortField: "email",
      selector: (row) => row.startDate ?? "",
      cell: (row) => (
        <div className="">
          {moment(row.startDate).format("DD MMM YYYY") ?? ""}
        </div>
      ),
    },
    {
      name: "End Date",
      sortable: true,
      sortField: "email",
      selector: (row) => row.endDate ?? "",
      cell: (row) => (
        <div className="">
          {moment(row.endDate).format("DD MMM YYYY") ?? ""}
        </div>
      ),
    },
    {
      name: "Remaining",
      sortable: true,
      selector: (row) => null,
      cell: (row) => (
        <div
          className={classNames("fw-bolder", {
            "text-danger":
              row.status === "Active" &&
              moment(row.endDate)
                .endOf("day")
                .diff(moment().startOf("day"), "days") < 30,
            "text-success":
              row.status === "Active" &&
              moment(row.endDate)
                .endOf("day")
                .diff(moment().startOf("day"), "days") >= 30,
          })}
        >
          {row.status === "Terminated" || row.status === "Expired" ? (
            "-"
          ) : (
            <>
              {moment(row.endDate)
                .endOf("day")
                .diff(moment().startOf("day"), "days") >= 30 ? (
                convertDaysToMonthsAndYears(
                  moment(row.endDate)
                    .endOf("day")
                    .diff(moment().startOf("day"), "days")
                )
              ) : moment(row.endDate)
                  .endOf("day")
                  .diff(moment().startOf("day"), "days") >= 1 ? (
                moment(row.endDate)
                  .endOf("day")
                  .diff(moment().startOf("day"), "days") + " days"
              ) : (
                <span>
                  <AlertTriangle
                    size={14}
                    className="text-danger align-middle me-1"
                  />
                  0 days
                </span>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      name: "Status",
      minWidth: "100px",
      sortable: true,
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <Badge
          color={
            row.status?.toLowerCase() == "active"
              ? "success"
              : row.status?.toLowerCase() == "expired"
              ? "warning"
              : "danger"
          }
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
    {
      name: "Docs",
      // minWidth: "100px",
      sortable: true,
      sortField: "status",
      selector: (row) => row.documents,
      cell: (row) => (
        <UncontrolledDropdown className="">
          <DropdownToggle
            tag="button"
            className="btn btn-sm h-100 btn-primary"
            size="10px"
            color="primary"
          >
            <EyeFill size={16} className="text-white" />
          </DropdownToggle>
          <DropdownMenu>
            {row?.documents?.map((doc) => (
              <DropdownItem
                key={doc?._id}
                tag="a"
                target={"_blank"}
                className="w-100"
                href={`${themeConfig.serverUrl}uploads/${doc.documentPath}`}
              >
                <FileEarmarkPdf size={14} /> {doc.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },

    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="d-flex">
          <UncontrolledDropdown className="">
            <DropdownToggle tag="div" className="btn btn-sm h-100 px-1 ">
              <MoreVertical size={14} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              {row.status === "Active" && (
                <>
                  <DropdownItem
                    tag="a"
                    href="/"
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedRow(row);
                      setGenerateModal(true);
                    }}
                  >
                    <ReceiptCutoff size={14} className="me-50" />
                    <span className="align-middle">Generate Invoice</span>
                  </DropdownItem>

                  <DropdownItem
                    tag="a"
                    href="/"
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      // setSelectedRow(row);
                      // setTerminateModal(true);
                      handleTerminateAgreement(row);
                    }}
                  >
                    <Link45deg size={14} className="me-50" />
                    <span className="align-middle">Terminate</span>
                  </DropdownItem>
                </>
              )}

              {row.status != "Terminated" && (
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedRow(row);
                    setRenewModal(true);
                  }}
                >
                  <RefreshCcw size={14} className="me-50" />
                  <span className="align-middle">Renew</span>
                </DropdownItem>
              )}

              {row.status === "Expired" && (
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    reclaimUnits(row);
                  }}
                >
                  <RefreshCcw size={14} className="me-50" />
                  <span className="align-middle">Reclaim Unit</span>
                </DropdownItem>
              )}

              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/leases/${row._id}`);
                }}
              >
                <Eye size={14} className="me-50" />
                <span className="align-middle">View Detail</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
      building: currentBuilding?._id,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };
  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handleFilter = (val) => {
    setsearchTerm(val);
    dispatch(searchFilter(val));
  };

  const dataToRender = () => {
    return store.data;
  };

  const ToggleModal = (show) => {
    if (typeof show == "boolean") {
      setModalShow(show);
    } else {
      setModalShow(!showmodal);
    }
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
        building: currentBuilding?._id,
      })
    );
    setRowsPerPage(value);
  };

  const getAgreementsStats = async () => {
    try {
      setAgreementStatsLoading(true);
      const { data, status, error } = await axios.get(
        `/agreements/stats?building=${currentBuilding?._id}`
      );

      if (status == 200) {
        setAgreementStats(data.data);
      } else {
        throw Error(data);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setAgreementStatsLoading(false);
    }
  };

  const onClose = () => {
    setSelectedRow(null);
    setSelectedRows([]);
    setGenerateModal(false);
  };

  const onCloseTerminate = () => {
    setSelectedRow(null);

    setTerminateModal(false);
  };
  const onCloseRenew = () => {
    setSelectedRow(null);

    setRenewModal(false);
  };

  const BootstrapCheckbox = forwardRef((props, ref) => (
    <div className="form-check">
      <Input type="checkbox" ref={ref} {...props} />
    </div>
  ));

  useEffect(() => {
    dispatch(getAllagreements({ building: currentBuilding?._id, status }));

    store.data.map((row) => {
      {
        moment(row.endDate).diff(moment(), "days") <= 4 &&
        moment(row.endDate).diff(moment(), "days") >= 1
          ? toast.success(`
      ${row?.agreementName ?? ""} Leases will expire after ${moment(
              row.endDate
            ).diff(moment(), "days")} Days.`)
          : "";
      }
    });
  }, [status]);

  useEffect(() => {
    getAgreementsStats();
  }, []);

  return (
    <Fragment>
      <GenerateInvoiceModel
        show={generateModal}
        row={selectedRow}
        rows={selectedRows}
        onClose={onClose}
      />
      <TerminateAgreement
        show={terminateModal}
        row={selectedRow}
        onClose={onCloseTerminate}
      />
      <RenewAgreement
        show={renewModal}
        row={selectedRow}
        onClose={onCloseRenew}
      />

      <Row>
        <Col
          lg="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => setSearchParams({})}
        >
          <StatsHorizontal
            color="primary"
            statTitle="All Agreements"
            icon={<FileText size={20} />}
            renderStats={
              !agreementStatsLoading ? (
                <h3 className="fw-bolder mb-75">{agreementStats?.All ?? 0}</h3>
              ) : (
                <Spinner />
              )
            }
          />
        </Col>
        <Col
          lg="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => setSearchParams({ status: "Active" })}
        >
          <StatsHorizontal
            color="success"
            statTitle="Active Agreements"
            icon={<FileText size={20} />}
            renderStats={
              !agreementStatsLoading ? (
                <h3 className="fw-bolder mb-75">
                  {agreementStats?.Active ?? 0}
                </h3>
              ) : (
                <Spinner />
              )
            }
          />
        </Col>
        <Col
          lg="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => setSearchParams({ status: "Terminated" })}
        >
          <StatsHorizontal
            color="danger"
            statTitle="Terminated Agreements"
            icon={<FileText size={20} />}
            renderStats={
              !agreementStatsLoading ? (
                <h3 className="fw-bolder mb-75">
                  {agreementStats?.Terminated ?? 0}
                </h3>
              ) : (
                <Spinner />
              )
            }
          />
        </Col>
        <Col
          lg="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => setSearchParams({ status: "Expired" })}
        >
          <StatsHorizontal
            color="warning"
            statTitle="Expired Agreements"
            icon={<FileText size={20} />}
            renderStats={
              !agreementStatsLoading ? (
                <h3 className="fw-bolder mb-75">
                  {agreementStats?.Expired ?? 0}
                </h3>
              ) : (
                <Spinner />
              )
            }
          />
        </Col>
      </Row>
      <Card>
        <CardBody className="d-flex align-items-start justify-content-between">
          <CardTitle tag="h4">Leases Management</CardTitle>
          <Can2 I="create" a="Agreement">
            <Link
              to={"/leases/create"}
              className="btn btn-primary"
              color="primary"
            >
              <PlusCircleDotted className="mr-1" size={20} /> New Lease
            </Link>
          </Can2>
        </CardBody>
        {/* <CardBody>
          <Row>
            <Col sm={12} md={4}>
              <Label for="role-select">Filter by status</Label>
              <Select
                isClearable={false}
                options={[]}
                className="react-select"
                classNamePrefix="select"
                onChange={(data) => {}}
              />
            </Col>
            <Col sm={12} md={4}>
              <Label for="role-select">Filter by customer</Label>
              <Select className="react-select" classNamePrefix="select" />
            </Col>
            <Col sm={12} md={4}>
              <Label for="role-select">Status</Label>
              <Select className="react-select" classNamePrefix="select" />
            </Col>
          </Row>
        </CardBody> */}
      </Card>
      <Card className="overflow-hidden">
        <CardBody className="mb-0 pb-0 text-end d-flex align-items-center justify-content-end">
          <h6 className="me-1">Actions: </h6>
          <ButtonGroup size="sm">
            <Button
              color="success"
              className=""
              onClick={() => setGenerateModal(true)}
              disabled={!selectedRows.length}
            >
              <ReceiptCutoff size={15} />
            </Button>

            <Button
              color="primary"
              className="ms-2"
              title=""
              onClick={() => reclaimUnits(true)}
              // disabled={!selectedRows.length}
            >
              <RefreshCcw className="me-1" />
              Reclaim Units
            </Button>
            {/*           
            <Button color="danger">
              <Trash2 size={15} />
            </Button> */}
          </ButtonGroup>
        </CardBody>
        <div className="">
          {store.loading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              striped
              selectableRows
              onSelectedRowsChange={(row) => {
                var ids = row.selectedRows
                  .filter((sr) => sr.status == "Active")
                  .map((rw) => rw._id);
                setSelectedRows(ids);
              }}
              columns={columns}
              // onSort={handleSort}
              sortIcon={<ChevronDown />}
              className="react-dataTable react-dataTable-selectable-rows"
              paginationComponent={CustomPagination}
              data={dataToRender()}
              progressPending={store.loading && !store.data.length}
              progressComponent={<TableLoading rows={1} cols={7} />}
              selectableRowsComponent={BootstrapCheckbox}
              subHeaderComponent={
                <CustomHeader
                  store={store}
                  searchTerm={searchTerm}
                  rowsPerPage={rowsPerPage}
                  handleFilter={handleFilter}
                  handlePerPage={handlePerPage}
                  // toggleSidebar={toggleSidebar}
                />
              }
            />
          )}
        </div>
      </Card>
    </Fragment>
  );
}
