// ** React Imports
import { Fragment, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Alert,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import axios from "axios";

import { ErrorHandler, ExtractError } from "../../../common/utils/Error";
import { Check2Circle } from "react-bootstrap-icons";
import moment from "moment";
import { useEffect } from "react";
import Select from "react-select";
import { useContext } from "react";
import { CurrentBuildingContext } from "../../../contexts/currentBuilding";
import { useParams, useSearchParams } from "react-router-dom";
import { getAllinvoices } from "../../../redux/invoice";

const RentInvoiceModel = ({ show, onClose, selectedRow, setSelectedRow }) => {
  const [loading, setLoading] = useState(false);
  const [customerInvoices, setcustomerInvoices] = useState([]);
  const [customerLeases, setcustomerLeases] = useState([]);
  const [customers, setAllCustomers] = useState([]);
  const [CustomerInvoicesloading, setInvoicesloading] = useState(false);
  const selectedBuilding = useSelector(
    (state) => state.buildings?.selectedBuilding
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");
  const status = searchParams.get("status");
  const type = searchParams.get("type");

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState(null);

  const { id } = useParams();

  const { currentBuilding } = useContext(CurrentBuildingContext);

  const getAllCustomers = async () => {
    try {
      const params = {};
      if (currentBuilding) {
        params.building = currentBuilding?._id;
      }
      const { data, status } = await axios.get(`/customers/list`, { params });

      if (status == 200) {
        setAllCustomers(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
      ErrorHandler(error);
    }
  };

  const getCustomerLeases = async (idx) => {
    setInvoicesloading(true);
    try {
      var { status, data } = await axios.get(
        `/agreements/customer/${idx}/${currentBuilding?._id}`
      );
      if (status == 200) {
        if (data.data?.length) {
          setcustomerLeases(data.data);
        } else {
          setcustomerLeases([]);
        }
      } else {
        setcustomerInvoices([]);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesloading(false);
    }
  };

  const defaultValues = {
    invoice_date: moment().format("YYYY-MM-DD"),
    type: "single",
    agreement: "",
    customer: "",
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      data.agreement = data.agreement.value;
      data.customer = data.customer.value;

      var inv = await axios.post("/accountings/invoices/generate", {
        ...data,
        type: "single",
      });
      if (inv) {
        toast.success(`Invoice Generated`);
        reset();
        onClose();
      }
      dispatch(
        getAllinvoices({ view: appView ? [appView] : [...c], status, type })
      );
    } catch (error) {
      setErrorMessage(ExtractError(error));
    }
    setLoading(false);
  };

  const onDiscard = () => {
    clearErrors();
    setSelectedRow && setSelectedRow(null);
    reset(defaultValues);
    onClose();
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  useEffect(() => {
    const customer = customers.find(
      (customer) => customer._id == selectedRow?.customer?._id
    );
    if (customer && show) {
      reset({
        ...defaultValues,
        customer: {
          value: customer?._id,
          label: customer?.name,
        },
      });
      getCustomerLeases(customer._id);
    }
  }, [show, customers]);

  return (
    <Fragment>
      <Modal isOpen={show} onClosed={onDiscard} toggle={onClose} className="">
        <ModalHeader toggle={onClose} className="bg-white">
          Rent Invoice Information
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            {errorMessage && (
              <Alert color="danger" className="px-2 py-1">
                {errorMessage}
              </Alert>
            )}

            <Row className="gy-1 gx-2 flex-column mb-1">
              <Col xs={12} md={12}>
                <Label className="form-label" for="customer">
                  Select Customer
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="customer"
                      className="react-select w-100"
                      classNamePrefix="select"
                      invalid={errors.customer && true}
                      {...register("customer", { required: true })}
                      {...field}
                      placeholder={"Select Customer"}
                      // value={customers.find(b=>b.value==field.value)}
                      options={
                        customers.map((cust) => ({
                          value: cust._id,
                          label: cust.name,
                        })) ?? []
                      }
                      readOnly={id || false}
                      onChange={(v) => {
                        field.onChange(v);
                        getCustomerLeases(v.value);

                        // var customer = data.InvoiceCustomers?.find(
                        //   (sec) => sec._id == v.value
                        // );
                        // var invs = customer?.invoices.map((inv) => ({
                        //   ...inv,
                        //   value: inv?._id,
                        //   label: `${inv?.invoiceNo ?? "" } - ${inv.type} - ${moment(inv.date).format("DD/MM/YYYY")} - $${
                        //     inv.balance
                        //   }  `,
                        // }));
                        // setcustomerInvoices(invs);
                      }}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                )}
              </Col>
              <Col xs={12} md={12}>
                <Label className="form-label" for="agreement">
                  Select Lease
                </Label>
                <Controller
                  name="agreement"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="agreement"
                      className="react-select w-100"
                      classNamePrefix="select"
                      invalid={errors.agreement && true}
                      {...field}
                      placeholder={"Select Lease"}
                      // value={customers.find(b=>b.value==field.value)}
                      options={
                        customerLeases.map((cust) => ({
                          value: cust._id,
                          label: `${cust.unit?.name} - ${cust.building?.name}`,
                        })) ?? []
                      }
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <Label className="form-label" for="invoice_date">
                  Invoice Date
                </Label>
                <Controller
                  name="invoice_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="invoice_date"
                      type="date"
                      invalid={errors.invoice_date && true}
                      {...field}
                    />
                  )}
                />
                {errors.invoice_date && (
                  <FormFeedback>Please enter Invoice Date</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="success"
              disabled={loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default RentInvoiceModel;
